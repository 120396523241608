


export class BrowserAppEvaluationToolInfo {

  public version = '2024.6.26.2';

  constructor() {
  }

}
